import React, {useState, useRef} from 'react'
import {graphql, useStaticQuery} from 'gatsby'
import Head from '../components/head'
import {Link} from 'gatsby'
import Img from 'gatsby-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons'
import headerStyles from '../components/header.module.css'
import contactoStyles from './contacto.module.css'
import layoutStyles from '../components/layout.module.css'

export default function Contacto() {
    const data = useStaticQuery(graphql`
        query {
            file(relativePath: {eq: "images/logo-E.png"}) {
                id
                childImageSharp {
                    fixed(width: 55, height: 55) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `)

    const [sended, setSended] = useState(false)
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)
    const nameRef = useRef()
    const emailRef = useRef()
    const messageRef = useRef()
    const sources = [{...data.file.childImageSharp.fixed}]

    async function handleSubmit(e) {
        e.preventDefault()
        const requestBody = {
            name: nameRef.current.value, 
            email: emailRef.current.value,
            message: messageRef.current.value
        }
        setLoading(true)
        const rawResponse = await fetch('/api/send-email', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestBody)
        })
        const content = await rawResponse.json()
        setLoading(false)
        if(content.error) {
            setError(true)
        }
        else {
            setSended(true)
        }
    }

    const header = (
        <header className={headerStyles.header}>
            <Head title='Contacto' />
            <div className={`${headerStyles.navbarContainer} ${contactoStyles.contactNavbarContainer}`}>
                <div className={`${headerStyles.navbarBrand} ${contactoStyles.contactNavbar}`}>
                    <Link to='/' className={headerStyles.headerHomeLink}>
                        <Img fixed={sources} alt='logo de Eduardo Ayora' />
                    </Link>
                    <Link to='/' className={headerStyles.headerHomeLink}>
                        <FontAwesomeIcon icon={faTimes} className='fa-lg' />
                    </Link>
                </div>
            </div>
        </header>
    )

    if(loading) {
        return (
            <SubmittedScreen header={header}>
                Enviando...
            </SubmittedScreen>
        )
    }
    
    if(error) {
        const errorStyle = {
            color: '#FC100D'
        }
        return (
            <SubmittedScreen header={header} failed={true}>
                <Link to='/' style={errorStyle}>
                    <FontAwesomeIcon icon={faTimes} className='fa-4x' />
                </Link>
                <Link to='/' style={errorStyle}>
                    <p>El mensaje no se pudo enviar</p>
                </Link>
            </SubmittedScreen>
        )
    }
        
    if(sended) {
        const successStyle = {
            color: '#4BB543'
        }
        return (
            <SubmittedScreen header={header}>
                <Link to='/' style={successStyle}>
                    <FontAwesomeIcon icon={faCheck} className='fa-4x' />
                </Link>
                <Link to='/' style={successStyle}>
                    <p>Mensaje enviado con éxito</p>
                </Link>
            </SubmittedScreen>
        )
    }
    
    return (
        <div>
            {header}
            <div className={'container'}>
                <div className='three-quarters'>
                    <div className={`columns ${contactoStyles.contactColumns}`}>
                        <div className={'col'}>
                            <h1 className={contactoStyles.contactTitle}>
                                Gracias por tomarse el tiempo de comunicarse. ¿Cómo le puedo ayudar?
                            </h1>
                        </div>
                    </div>
                </div>
                <form className={contactoStyles.contactForm}>
                    <div className='three-quarters'>
                        <div className={`columns ${contactoStyles.contactColumns}`}>
                            <div className={'col col-md-6'}>
                                <label htmlFor='name'>Nombre</label>
                                <input id='name' ref={nameRef} type='text'/>
                            </div>
                            <div className={'col col-md-6'}>
                                <label htmlFor='email'>Email</label>
                                <input id='email' ref={emailRef} type='email'/>
                            </div>
                            <div className={'col'}>
                                <label htmlFor='message'>Mensaje</label>
                                <textarea id='message' ref={messageRef} rows='5'/>
                            </div>
                            <div className={`col col-md-4 ${contactoStyles.selfCenter}`}>
                                <button className={'link-button'} onClick={handleSubmit}>Enviar</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

function SubmittedScreen({header, children}) {
    return (
        <div className={layoutStyles.layoutContainer}>
            {header}
            <div className={contactoStyles.contactConfirmedContainer}>
                {children}
            </div>
        </div>
    )
}
